export const groceries = [
  "Pet Care",
  "Stationery",
  "Detergents and Dishwash",
  "Dairy and Cheese",
  "Snacks, Dry Fruits, Nuts",
  "Pasta, Soup and Noodles",
  "Cereals and Breakfast",
  "Sauces, Spreads and Dips",
  "Chocolates and Biscuits",
  "Cooking and Baking Needs",
  "Tinned and Processed Food",
  "Atta, Flours and Sooji",
  "Rice and Rice Products",
  "Dals and Pulses",
  "Salt, Sugar and Jaggery",
  "Energy and Soft Drinks",
  "Water",
  "Tea and Coffee",
  "Fruit Juices and Fruit Drinks",
  "Snacks and Namkeen",
  "Ready to Cook and Eat",
  "Pickles and Chutney",
  "Indian Sweets",
  "Frozen Vegetables",
  "Frozen Snacks",
  "Gift Voucher",
  "Gourmet & World Foods",
  "Foodgrains",
  "Beverages",
  "Beauty & Hygiene",
  "Kitchen Accessories",
  "Baby Care",
  "Snacks & Branded Foods",
];


export const fashion = [
    "Shirts",
    "T Shirts",
    "Sweatshirts",
    "Kurtas & Kurta Sets",
    "Jackets & Coats",
    "Sweaters",
    "Stoles and Scarves",
    "Mufflers",
    "Suits",
    "Sherwanis",
    "Track Shirts",
    "Track Suits",
    "Unstitched Fabrics",
    "Dresses",
    "Tops",
    "Trousers",
    "Capris",
    "Coordinates",
    "Playsuits",
    "Jumpsuits",
    "Shrugs & Blouses",
    "Blazers & Waistcoats",
    "Tights, Leggings & Jeggings",
    "Track Pants",
    "Jeans",
    "Shorts",
    "Joggers",
    "Dhotis & Dhoti Pants",
    "Churidars",
    "Salwars",
    "Dungarees & Jumpsuits",
    "Skirts",
    "Clothing Sets",
    "Belts",
    "Caps & Hats",
    "Kurtis, Tunics",
    "Sarees",
    "Ethnic Wear",
    "Palazzos",
    "Dress Materials",
    "Lehenga Cholis",
    "Dupattas & Shawls",
    "Burqas & Hijabs",
    "Blouses",
    "Blouse Pieces",
    "Briefs",
    "Boxers",
    "Vests",
    "Robes",
    "Night Suits",
    "Thermal Wear",
    "Swim Bottoms",
    "Swimwear",
    "Bra",
    "Shapewear",
    "Sleepwear & Loungewear",
    "Camisoles",
    "Lingerie Sets & Accessories",
    "Bath Robes",
    "Towels",
    "Pyjamas",
    "Party Wear",
    "Innerwear & Sleepwear",
    "Nightwear & Loungewear",
    "Watches",
    "Gloves",
    "Socks",
    "Stockings",
    "Laces",
    "Soles & Charms",
    "Shoe Racks & Organisers",
    "Shoe Care - Accessories",
    "Flip-Flops & Flats",
    "Sandals & Floaters",
    "Backpacks",
    "Handbags",
    "Trolley, Luggage & Suitcases",
    "Formal Shoes",
    "Casual Shoes",
    "Sports Shoes",
    "Outdoor Shoes",
    "Work & Safety Shoes",
    "Ethnic Shoes",
    "Boots",
    "Heels",
    "Contact Lenses",
    "Eye Glasses",
    "Eye Glass Frames",
    "Sunglasses",
    "Contact Lens Cases",
    "Contact Lens Solutions",
    "Contact Lens Tweezers",
    "Eyeglasses Pouches & Cases",
    "Microfiber Wipes",
    "Eyewear Slings",
    "Bracelets",
    "Chains",
    "Mangalsutra",
    "Anklets",
    "Bangles & Bracelets",
    "Necklaces",
    "Earrings",
    "Jewellery Sets",
    "Nosepins & Noserings",
    "Pendants",
    "Rings",
    "Toe Rings",
    "Gold Coins",
    "Brooch",
    "Pouch",
    "Keychain"
  ];
  