import axios from "axios";
import { userAppBaseURL } from "services/url";
import { getStorageItem } from "services/storage";
const adminType = getStorageItem("adminType");
export const getOfferList = async (page: number = 1, size: string = "10") => {
  const searchParams = new URLSearchParams();
  searchParams.set("page", String(page));
  searchParams.set("size", String(size));
  if (adminType === "e-commerce-b2b") {
    searchParams.set("isBusiness", String(true));
  }
  const response = await axios({
    method: "GET",
    url: userAppBaseURL + "/offers/all",
    params: searchParams,
    headers: {
      "ngrok-skip-browser-warning": "69420",
      Authorization: `${getStorageItem("token")}`,
    },
  });
  return response.data;
};

export const getOfferDetails = async (offerId: string) => {
  const searchParams = new URLSearchParams();
  if (adminType === "e-commerce-b2b") {
    searchParams.set("isBusiness", String(true));
  }
  if (offerId) {
    const response = await axios({
      method: "GET",
      url: `${userAppBaseURL}/offers/${offerId}`,
      params: searchParams,
      headers: {
        "ngrok-skip-browser-warning": "69420",
        Authorization: `${getStorageItem("token")}`,
      },
    });
    return await response.data;
  }
};

export const postOffer = async (offerData: any) => {
  const response = await axios({
    method: "POST",
    url: `${userAppBaseURL}/offers`,
    headers: {
      "ngrok-skip-browser-warning": "69420",
      Authorization: `${getStorageItem("token")}`,
    },
    data: {
      ...offerData,
    },
  });
  return await response.data;
};

export const patchOffer = async (offerId: string, offerData: any) => {
  // const response = await axios({
  //   method: "PATCH",
  //   url: `${userAppBaseURL}/offers/${offerId}`,
  //   headers: {
  //     Authorization: `${getStorageItem("token")}`,
  //   },
  //   data: {
  //     ...offerData,
  //   },
  // })
  // return await response.data
};

export const deleteOffer = async (offerId: string) => {
  const response = await axios({
    method: "DELETE",
    url: `${userAppBaseURL}/offers/${offerId}`,
    headers: {
      "ngrok-skip-browser-warning": "69420",
      Authorization: `${getStorageItem("token")}`,
    },
  });
  return await response;
};
