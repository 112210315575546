import { Modal } from "components/Common/Modal";
import BasicDetails from "./BasicDetails";

const AddPagesModal = ({
  isAddPageModalVisible,
  setAddPageModalVisible,
  editPageModal,
  setEditPageModal,
}: {
  isAddPageModalVisible: boolean;
  setAddPageModalVisible: (isAddOfferModalVisible: boolean) => void;
  editPageModal: any;
  setEditPageModal: Function;
}) => {
  console.log("🚀 ~ editPageModal:", editPageModal)
  return (
    <Modal
      opened={isAddPageModalVisible || editPageModal.isOpen}
      onClose={() => {
        if (isAddPageModalVisible) {
          setAddPageModalVisible(false);
        } else {
          setEditPageModal({
            isOpen: false,
            page: "",
          });
        }
      }}
      title="Add a Page"
    >
      <BasicDetails
        editPageModal={editPageModal}
        setEditPageModal={setEditPageModal}
        setAddPageModalVisible={setAddPageModalVisible}
      />
    </Modal>
  );
};

export default AddPagesModal;
