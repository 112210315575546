import { useEffect, useState } from "react";
import {
  Input,
  NumberInput,
  Radio,
  Select,
  Switch,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePicker, TimeInput } from "@mantine/dates";
import ReactQuill from "react-quill";
import { Dropzone } from "components/Common/Dropzone";
import { Button } from "components/Common/Button";
import { getCategories, getOfferDetails } from "services/apis/admin";
import "react-quill/dist/quill.snow.css";
import { checkAuth } from "utils/helper";
import { useNotification } from "context";
import { useNavigate } from "react-router-dom";
import {
  useAddOfferMutation,
  useUpdateOfferMutation,
} from "hooks/Admin/offersAPI";
import { DownArrowSVG } from "assets/icons";
import { useGetSubCategoryListQuery } from "hooks/Admin/subCategoryAPI";
import { getStorageItem } from "services/storage";

const adminType = getStorageItem("adminType");

const BasicDetails = ({
  setAddOfferModalVisible,
  setEditOfferModal,
  editOfferModal,
}: {
  setAddOfferModalVisible: Function;
  setEditOfferModal: Function;
  editOfferModal: any;
}) => {
  const [offerLogo, setOfferLogo] = useState<any>("");
  const [allCategories, setAllCategories] = useState([]);
  const [offerCoverValidationText, setOfferCoverValidationText] = useState("");
  const offerDetailsForm = useForm<any>({
    validateInputOnChange: true,
    initialValues: {
      title: "",
      couponCode: "",
      description: "",
      category: "",
      subCategory: "",
      offerDetails: {
        offerLevel: "GLOBAL",
        offerType: "ORDER",
        usage: {
          usageType: "",
          repeatNumber: "",
        },
        discountType: "",
        minOrderValue: "",
        discountValue: "",
        discountCapping: "",
        discountOn: "",
        sortOrder: "",
      },
      startDate: new Date(),
      endDate: new Date(),
      operationalHours: {
        startTime: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          9,
          0,
          0,
          0
        ),
        endTime: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          21,
          0,
          0,
          0
        ),
      },
      isActive: false,
      isVisible: false,
      frequency: "",
      userType: "",
    },

    validate: {
      title: (value) => (value?.length === 0 ? "Title is required" : null),
      couponCode: (value) =>
        value?.length === 0 ? "Coupon code is required" : null,
      description: (value) =>
        value.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
        !value.includes("<img")
          ? "Description is required"
          : null,
      startDate: (value) =>
        !value || String(value)?.length === 0 ? "Start date is required" : null,
      endDate: (value) =>
        !value || String(value)?.length === 0 ? "End date is required" : null,
      frequency: (value) =>
        value?.length === 0 ? "Frequency is required" : null,
      offerDetails: {
        offerLevel: (value) =>
          value?.length === 0 ? "Offer level is required" : null,
        discountType: (value) =>
          value?.length === 0 ? "Discount type is required" : null,
        discountOn: (value) =>
          value?.length === 0 ? "Discount on is required" : null,
        offerType: (value) =>
          value?.length === 0 ? "Offer type is required" : null,
        minOrderValue: (value, values) =>
          values?.offerDetails?.offerType === "ORDER" &&
          (!value || value?.length === 0)
            ? "Order value is required"
            : value && !/^\d+$/.test(value)
            ? "Order value should be a number"
            : null, // null check
        discountValue: (value, values) => {
          const discountValueNumber = Number(value);
          const minOrderValueNumber = Number(
            values?.offerDetails?.minOrderValue
          );
          return (values?.offerDetails?.offerType === "ORDER" ||
            values?.offerDetails?.offerType === "DISCOUNT") &&
            (!value || value?.length === 0)
            ? "Discount value is required"
            : value && !/^\d+$/.test(value)
            ? "Discount value should be a number"
            : values?.offerDetails?.discountType === "PERCENTAGE" &&
              discountValueNumber > 100
            ? "Discount value should be less than or equal to 100"
            : values?.offerDetails?.discountType === "FLAT" &&
              discountValueNumber > minOrderValueNumber
            ? "Discount value should not exceed the minimum order value for FLAT discount"
            : null;
        },
        discountCapping: (value, values) =>
          values?.offerDetails?.offerType === "DISCOUNT" &&
          (!value || value?.length === 0)
            ? "Discount capping is required"
            : value && !/^\d+$/.test(value)
            ? "Discount capping should be a number"
            : null,
        usage: {
          usageType: (value) =>
            value?.length === 0 ? "Usage type is required" : null,
          repeatNumber: (value, values) =>
            values.usageType === "REPEATABLE" && value?.length === 0
              ? "Repeat limit is required"
              : null,
        },
      },
      category: (value, values) => {
        return values.offerLevel === "CATEGORY" &&
          (!value || String(value)?.length === 0)
          ? "Category is required"
          : null;
      },

      userType: (value, values) =>
        (values?.offerDetails?.offerType === "ORDER" ||
          values?.offerDetails?.offerType === "DISCOUNT") &&
        value?.length === 0
          ? "User type is required"
          : null,
    },
  });

  const { setText } = useNotification();
  const navigate = useNavigate();
  const [updateOffer] = useUpdateOfferMutation();
  const [addOffer] = useAddOfferMutation();
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const { data: subCategoryList } = useGetSubCategoryListQuery({
    categoryId: selectedCategoryId,
  });
  const subCategoryListOptions =
    subCategoryList?.data?.map((subCat: any) => ({
      value: subCat._id,
      label: subCat.subCategoryName,
    })) || [];

  const fetchOfferDetails = async (id: any) => {
    const response = await getOfferDetails(id);
    if (response.success) {
      setOfferLogo(response.data.img);
      offerDetailsForm.setValues({
        title: response.data.title,
        couponCode: response.data.couponCode,
        description: response.data.description,
        category: response?.data?.category?._id,
        subCategory: response?.data?.subCategory?._id,
        offerDetails: {
          offerLevel: response.data.offerDetails.offerLevel,
          offerType: response.data.offerDetails.offerType,
          usage: {
            usageType: response.data.offerDetails.usage.usageType,
            repeatNumber: response.data.offerDetails.usage.repeatNumber,
          },
          discountType: response.data.offerDetails.discountType,
          minOrderValue: response.data.offerDetails.minOrderValue,
          discountValue: response.data.offerDetails.discountValue,
          discountCapping: response.data.offerDetails.discountCapping,
          discountOn: response.data.offerDetails.discountOn,
          sortOrder: response.data.offerDetails.sortOrder,
        },
        startDate: new Date(response.data.startDate),
        endDate: new Date(response.data.endDate),
        operationalHours: {
          startTime: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            new Date(response.data.operationalHours.startTime).getHours(),
            new Date(response.data.operationalHours.startTime).getMinutes(),
            0,
            0
          ),
          endTime: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            new Date(response.data.operationalHours.endTime).getHours(),
            new Date(response.data.operationalHours.endTime).getMinutes(),
            0,
            0
          ),
        },
        isActive: response.data.isActive,
        isVisible: response.data.isVisible,
        frequency: response.data.frequency,
        userType: response.data.userType,
      });
    }
  };

  useEffect(() => {
    if (editOfferModal.isOpen) {
      fetchOfferDetails(editOfferModal?.offer?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOfferModal.isOpen]);

  useEffect(() => {
    if (editOfferModal?.isOpen && offerDetailsForm?.values) {
      setSelectedCategoryId(offerDetailsForm.values.category);
    }
  }, [editOfferModal?.isOpen, offerDetailsForm.values]);

  useEffect(() => {
    (async () => {
      checkAuth(
        async () => {
          const response = await getCategories(1, "50");
          if (response) {
            setAllCategories(
              response.data.data.map((category: any) => ({
                value: category._id,
                label: category.categoryName,
              }))
            );
          }
        },
        setText,
        navigate
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleAddOffer() {
    if (!offerLogo) {
      setOfferCoverValidationText("Image is required");
      return;
    }

    let payload = {
      img: offerLogo._id,
      title: offerDetailsForm.values.title || undefined,
      couponCode: offerDetailsForm.values.couponCode || undefined,
      description: offerDetailsForm.values.description || undefined,
      startDate: offerDetailsForm.values.startDate || undefined,
      endDate: offerDetailsForm.values.endDate || undefined,
      category:
        offerDetailsForm.values.offerDetails.offerLevel === "CATEGORY"
          ? offerDetailsForm.values.category
          : undefined,
      subCategory:
        offerDetailsForm.values.offerDetails.offerLevel === "CATEGORY"
          ? offerDetailsForm.values.subCategory
          : undefined,
      offerDetails: {
        offerLevel:
          offerDetailsForm.values.offerDetails.offerLevel || undefined,
        offerType: offerDetailsForm.values.offerDetails.offerType || undefined,
        usage: {
          usageType:
            offerDetailsForm.values.offerDetails.usage.usageType || undefined,
          ...(offerDetailsForm.values.offerDetails.usage.usageType ===
            "REPEATABLE" && {
            repeatNumber:
              offerDetailsForm.values.offerDetails.usage.repeatNumber ||
              undefined,
          }),
        },
        discountType:
          offerDetailsForm.values.offerDetails.discountType || undefined,
        minOrderValue:
          offerDetailsForm.values.offerDetails.minOrderValue || undefined,
        discountValue:
          offerDetailsForm.values.offerDetails.discountValue || undefined,
        discountCapping:
          offerDetailsForm.values.offerDetails.discountCapping || undefined,
        discountOn:
          offerDetailsForm.values.offerDetails.discountOn || undefined,
        sortOrder: offerDetailsForm.values.offerDetails.sortOrder || undefined,
      },
      operationalHours: {
        startTime:
          offerDetailsForm.values.operationalHours.startTime || undefined,
        endTime: offerDetailsForm.values.operationalHours.endTime || undefined,
      },
      isActive: offerDetailsForm.values.isActive,
      isVisible: offerDetailsForm.values.isVisible,

      frequency: offerDetailsForm.values.frequency || undefined,
      userType: offerDetailsForm.values.userType || undefined,
      isBusiness: adminType === "e-commerce-b2b" ? true : false,
    };

    const id = editOfferModal?.offer?._id;
    if (editOfferModal.isOpen) {
      const response = await updateOffer({ id, payload });
      if (response?.data?.success) {
        setEditOfferModal({
          isOpen: false,
          offer: "",
        });
      }
    } else {
      const response = await addOffer(payload);
      if (response.data.success) {
        setAddOfferModalVisible(false);
      }
    }
  }

  return (
    <form
      className="w-650 pt-20 pb-20 pl-20 pr-20"
      onSubmit={offerDetailsForm.onSubmit(handleAddOffer)}
    >
      <p className="txt-16 mb-20">Offer Details</p>
      <Dropzone
        mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
        media={offerLogo?.link}
        setMedia={(img) => {
          setOfferLogo(img[0]);
        }}
        mediaSize={2000000}
        mediaValidationText={offerCoverValidationText}
        setMediaValidationText={setOfferCoverValidationText}
      />
      <div className="grid grid-cols-2 col-gap-40 row-gap-25 pb-10">
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="OFFER TITLE"
          required
        >
          <TextInput
            placeholder="Enter Offer Title"
            variant="filled"
            {...offerDetailsForm.getInputProps("title")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="COUPON CODE"
          required
        >
          <TextInput
            placeholder="Enter Coupon Code"
            variant="filled"
            {...offerDetailsForm.getInputProps("couponCode")}
          />
        </Input.Wrapper>
        <div className="flex">
          <DatePicker
            withAsterisk
            placeholder="Pick Start Date"
            label="Start Date"
            classNames={{
              wrapper: "w-120 mr-10",
              label: "txt-12 txtw-4 clr-grey txt-ucase",
            }}
            minDate={new Date()}
            {...offerDetailsForm.getInputProps("startDate")}
          />
          <DatePicker
            withAsterisk
            placeholder="Pick End Date"
            label="End Date"
            classNames={{
              wrapper: "w-120",
              label: "txt-12 txtw-4 clr-grey txt-ucase",
            }}
            minDate={new Date(offerDetailsForm.values.startDate)}
            {...offerDetailsForm.getInputProps("endDate")}
          />
        </div>
        <div>
          <p className="txt-12 txtw-4 clr-grey txt-ucase mt-5">
            operational hours
          </p>
          <div className="flex">
            <TimeInput
              clearable
              variant="filled"
              format="12"
              {...offerDetailsForm.getInputProps("operationalHours.startTime")}
              classNames={{
                root: "mr-5",
              }}
            />
            <TimeInput
              clearable
              variant="filled"
              format="12"
              {...offerDetailsForm.getInputProps("operationalHours.endTime")}
            />
          </div>
        </div>
        <div className="gcol-start-1 gcol-end-3">
          <p className="clr-grey txtw-4 txt-12 txt-ucase">description</p>
          <ReactQuill
            theme="snow"
            {...offerDetailsForm.getInputProps("description")}
          />
          {offerDetailsForm.errors.description && (
            <p className="txt-12 clr-red mt-5">
              {offerDetailsForm.errors.description}
            </p>
          )}
        </div>

        <div className="flex justify-stretch align-center gcol-start-1 gcol-end-2">
          <p className="txt-12 txtw-5 txt-ucase clr-grey">offer active</p>
          <Switch
            checked={offerDetailsForm.values.isActive}
            color="primary"
            {...offerDetailsForm.getInputProps("isActive", {
              type: "checkbox",
            })}
          />
        </div>
        <div className="flex justify-stretch align-center gcol-start-2 gcol-end-3">
          <p className="txt-12 txtw-5 txt-ucase clr-grey">offer visible</p>
          <Switch
            checked={offerDetailsForm.values.isVisible}
            color="primary"
            {...offerDetailsForm.getInputProps("isVisible", {
              type: "checkbox",
            })}
          />
        </div>
        {/* <Input.Wrapper
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="SORT ORDER"
        >
          <NumberInput
            placeholder="Enter sort order"
            variant="filled"
            min={1}
            max={5}
            {...offerDetailsForm.getInputProps("offerDetails.sortOrder")}
          />
        </Input.Wrapper> */}

        <Radio.Group
          label="offer level"
          withAsterisk
          classNames={{
            root: "gcol-start-1 gcol-end-3",
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          {...offerDetailsForm.getInputProps("offerDetails.offerLevel")}
        >
          <Radio
            value="GLOBAL"
            label="Global Level"
            classNames={{
              label: "txt-12 txtw-4 clr-grey txt-cap",
            }}
          />
          <Radio
            value="CATEGORY"
            label="Category Level"
            classNames={{
              label: "txt-12 txtw-4 clr-grey txt-cap",
            }}
          />
        </Radio.Group>
        {offerDetailsForm.values.offerDetails.offerLevel === "CATEGORY" && (
          <Select
            data={allCategories}
            label="category"
            placeholder="Pick the Category"
            classNames={{
              root: "gcol-start-1 gcol-end-3",
              label: "txt-12 txtw-4 clr-grey txt-ucase",
            }}
            rightSection={<DownArrowSVG />}
            {...offerDetailsForm.getInputProps("category")}
            onChange={(e: any) => {
              offerDetailsForm.setFieldValue("category", e);
              setSelectedCategoryId(e);
            }}
          />
        )}

        {offerDetailsForm.values.category &&
        offerDetailsForm.values.offerDetails.offerLevel === "CATEGORY" ? (
          <Select
            data={subCategoryListOptions}
            disabled={!subCategoryListOptions.length}
            label="sub category"
            placeholder="Pick a sub category"
            classNames={{
              root: "gcol-start-1 gcol-end-3",
              label: "txt-12 txtw-4 clr-grey txt-ucase",
            }}
            rightSectionWidth={offerDetailsForm.values.subCategory ? 50 : 30}
            rightSection={
              offerDetailsForm.values.subCategory ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10px",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={(e) =>
                      offerDetailsForm.setFieldValue("subCategory", "")
                    }
                  >
                    remove
                  </span>
                </div>
              ) : (
                <DownArrowSVG />
              )
            }
            {...offerDetailsForm.getInputProps("subCategory")}
          />
        ) : null}
        <Select
          data={[
            { value: "ALL", label: "All" },
            { value: "SUNDAY", label: "Sunday" },
            { value: "MONDAY", label: "Monday" },
            { value: "TUESDAY", label: "Tuesday" },
            { value: "WEDNESDAY", label: "Wednesday" },
            { value: "THURSDAY", label: "Thursday" },
            { value: "FRIDAY", label: "Friday" },
            { value: "SATURDAY", label: "Saturday" },
          ]}
          label="Frequency"
          placeholder="Pick the days"
          classNames={{
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          rightSection={<DownArrowSVG />}
          {...offerDetailsForm.getInputProps("frequency")}
        />

        <Select
          label="usage type"
          placeholder="Pick usage"
          data={[
            { value: "ONE_TIME", label: "One Time" },
            { value: "REPEATABLE", label: "Repeatable" },
          ]}
          rightSection={<DownArrowSVG />}
          classNames={{
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          {...offerDetailsForm.getInputProps("offerDetails.usage.usageType")}
        />
        {offerDetailsForm.values.offerDetails.usage.usageType ===
          "REPEATABLE" && (
          <Input.Wrapper
            withAsterisk
            classNames={{
              label: "clr-grey txt-12 txtw-4",
            }}
            label="REPEAT LIMIT"
          >
            <NumberInput
              placeholder="Enter repeat limit"
              variant="filled"
              {...offerDetailsForm.getInputProps(
                "offerDetails.usage.repeatNumber"
              )}
              min={2}
            />
          </Input.Wrapper>
        )}
        <Select
          label="discount type"
          placeholder="Pick the type"
          data={[
            { value: "PERCENTAGE", label: "Percentage" },
            { value: "FLAT", label: "Flat" },
          ]}
          rightSection={<DownArrowSVG />}
          classNames={{
            root: "gcol-start-1 gcol-end-2",
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          {...offerDetailsForm.getInputProps("offerDetails.discountType")}
          onChange={(value: string | null) => {
            offerDetailsForm.setFieldValue("offerDetails.discountType", value);
            const currentDiscountValue =
              offerDetailsForm.values.offerDetails.discountValue;
            if (currentDiscountValue) {
              offerDetailsForm.setFieldValue("offerDetails.discountValue", "");
            }
          }}
        />

        <Select
          label="discount on"
          placeholder="Pick one"
          data={[
            { value: "NET", label: "Net" },
            { value: "GROSS", label: "Gross" },
          ]}
          rightSection={<DownArrowSVG />}
          classNames={{
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          {...offerDetailsForm.getInputProps("offerDetails.discountOn")}
        />

        <Radio.Group
          label="offer type"
          withAsterisk
          classNames={{
            root: "gcol-start-1 gcol-end-3",
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          {...offerDetailsForm.getInputProps("offerDetails.offerType")}
        >
          <Radio
            value="ORDER"
            label="Minimum Order Value Discount"
            classNames={{
              label: "txt-12 txtw-4 clr-grey txt-cap",
            }}
          />
          <Radio
            value="DISCOUNT"
            label="% Discount / Cash off"
            classNames={{
              label: "txt-12 txtw-4 clr-grey txt-cap",
            }}
          />
        </Radio.Group>
        {<OfferTypeForms offerDetailsForm={offerDetailsForm} />}
      </div>
      <div className="flex justify-end bd-top pt-10">
        <Button
          type="filled-primary"
          text={`${editOfferModal.isOpen ? "Save" : "Add"}`}
          loading={false}
          onClick={() => {}}
        />
      </div>
    </form>
  );
};

const OfferTypeForms = ({ offerDetailsForm }: any) => {
  if (offerDetailsForm.values.offerDetails.offerType === "ORDER")
    return (
      <>
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="MINIMUM ORDER VALUE"
        >
          <TextInput
            placeholder="Enter Value"
            variant="filled"
            {...offerDetailsForm.getInputProps("offerDetails.minOrderValue")}
            // required={
            //   offerDetailsForm.values.offerDetails.offerType === "ORDER"
            // }
          />
        </Input.Wrapper>
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="DISCOUNT VALUE"
        >
          <TextInput
            placeholder="Enter Value"
            variant="filled"
            {...offerDetailsForm.getInputProps("offerDetails.discountValue")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="DISCOUNT CAPPING"
        >
          <TextInput
            placeholder="Enter Value"
            variant="filled"
            {...offerDetailsForm.getInputProps("offerDetails.discountCapping")}
          />
        </Input.Wrapper>
        <Select
          label="user type"
          placeholder="Pick one"
          data={[
            { value: "ALL", label: "All" },
            { value: "NEW", label: "New" },
            { value: "REPEAT", label: "Repeat" },
            { value: "DORMANT", label: "Dormant" },
          ]}
          classNames={{
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          {...offerDetailsForm.getInputProps("userType")}
        />
      </>
    );

  if (offerDetailsForm.values.offerDetails.offerType === "DISCOUNT")
    return (
      <>
        <Input.Wrapper
          // withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="MINIMUM ORDER VALUE"
        >
          <TextInput
            placeholder="Enter Value"
            variant="filled"
            {...offerDetailsForm.getInputProps("offerDetails.minOrderValue")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          withAsterisk
          classNames={{
            label: "clr-grey txt-12 txtw-4",
          }}
          label="DISCOUNT VALUE"
        >
          <TextInput
            placeholder="Enter Value"
            variant="filled"
            // max={100}
            max={
              offerDetailsForm.values.offerDetails.discountType === "PERCENTAGE"
                ? 100
                : undefined
            }
            {...offerDetailsForm.getInputProps("offerDetails.discountValue")}
          />
        </Input.Wrapper>
        <Input.Wrapper
          withAsterisk
          classNames={{
            root: "gcol-start-1 gcol-end-2",
            label: "clr-grey txt-12 txtw-4",
          }}
          label="DISCOUNT CAPPING"
        >
          <TextInput
            placeholder="Enter Value"
            variant="filled"
            {...offerDetailsForm.getInputProps("offerDetails.discountCapping")}
          />
        </Input.Wrapper>
        <Select
          label="user type"
          placeholder="Pick one"
          data={[
            { value: "ALL", label: "All" },
            { value: "NEW", label: "New" },
            { value: "REPEAT", label: "Repeat" },
            { value: "DORMANT", label: "Dormant" },
          ]}
          classNames={{
            label: "txt-12 txtw-4 clr-grey txt-ucase",
          }}
          {...offerDetailsForm.getInputProps("userType")}
        />
      </>
    );
  if (offerDetailsForm.values.offerDetails.offerType === "FREE_DELIVERY")
    return (
      <Input.Wrapper
        withAsterisk
        classNames={{
          label: "clr-grey txt-12 txtw-4",
        }}
        label="MINIMUM ORDER VALUE"
      >
        <TextInput
          placeholder="Enter Value"
          variant="filled"
          {...offerDetailsForm.getInputProps("offerDetails.minOrderValue")}
        />
      </Input.Wrapper>
    );

  return null;
};

export default BasicDetails;
